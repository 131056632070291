import { BirthDate, HandleChangeProps, FormData, FieldStatus, UpdateStoreStateProps} from './inputTypes';
import { Entity, StringConditions, MoneyConditions} from './configTypes';
import { DATE_FORMAT, EMPTY_LOAN_OBJECT, EMPTY_BORROWER_OBJECT, CURRENCY_LABEL } from './consts';
import { parse, isValid, getDate, getYear, getMonth} from 'date-fns';


export const handleChange = ({
    value,
    entity,
    localState,
    field,
    setLocalState,
  }: HandleChangeProps) => {
  // if there isnt an entity (loan/borrower) object in localstate
  // grab the empty one
  let updatedLocalState;
  if (!localState.hasOwnProperty(entity)) {
    const emptyBaseObject = entity === 'Borrower' ? EMPTY_BORROWER_OBJECT : EMPTY_LOAN_OBJECT;
    updatedLocalState = {
      ...localState,
      [entity]: {
        ...emptyBaseObject,
        [field]: value,
      }
    };
  } else {
    updatedLocalState = {
      ...localState,
      [entity]: {
        ...localState[entity],
        [field]: value,
      }
    };
  };
  setLocalState(updatedLocalState);
  // if this gets expensive, potentially save entire localstate to localstorage every x seconds instead of onchange
  localStorage.setItem('localData', JSON.stringify(updatedLocalState));
};

export const updateStoreState = ({
  entity,
  field,
  value,
  storeState,
  setStoreState,
}: UpdateStoreStateProps): void => {
  let updatedStoreState;
  // if this is the first value of its entity to be added
  // we want to populate the other fields of that entity with their default empty values
  // so we use the EMPTY_{ENTITY}_OBJECTs
  if (!storeState.hasOwnProperty(entity)) {
    const emptyBaseObject = entity === 'Borrower' ? EMPTY_BORROWER_OBJECT : EMPTY_LOAN_OBJECT;
    updatedStoreState = {
      ...storeState,
      [entity]: {
        ...emptyBaseObject,
        [field]: value,
      }
    };
  } else {
    updatedStoreState = {
      ...storeState,
      [entity]: {
        ...storeState[entity],
        [field]: value,
      }
    };
  };
  setStoreState(updatedStoreState);
  localStorage.setItem('backStoreData', JSON.stringify(updatedStoreState));
  console.log('BACK STORE: ', JSON.parse(localStorage.getItem('backStoreData') || '{}'));
}

export const addCommas = (input: string): string => {
  return input.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const removeNonNumeric = (input: string): string => {
  if (!input) {
    return ''
  }
  return input.toString().replace(/[^0-9]/g, "");
}

export const unformatMoney = (input: string): number => {
  // remove non-numeric
  if (!input) {
    return NaN;
  };
  const numbersOnlyValue = removeNonNumeric(input);
  return Number(numbersOnlyValue);
}

// formatter from https://stackoverflow.com/a/16233919
export const formatter = new Intl.NumberFormat('en-US', {
  style: 'decimal', // using decimal vs currency so we can control when to show the dollar sign
  currency: 'USD',
});

export const isValidStringInput = (input: string, conditions: StringConditions): boolean => {
  const conditionAsRegex: RegExp = new RegExp(conditions.regex);
  return conditionAsRegex.test(input);
};

// potentially make this detect if too low/too high and provide feedback accordingly in the future
export const isValidMoneyInput = (input: number | null, conditions: MoneyConditions): boolean => {
  if (input === null) {
    return false;
  };
  // check min max
  return input >= conditions.minValue && input <= conditions.maxValue;
};

export const convertDateObjectToDate = (input: BirthDate): Date => {
  const dateObjectAsString = `${input.month}/${input.day}/${input.year}`;
  const dateStringAsDate = parse(dateObjectAsString, DATE_FORMAT, new Date());
  return dateStringAsDate;
};

export const convertDateToDateObject = (input: Date | null): BirthDate | null => {
  if (!isValid(input) || !input) {
    return null;
  }
  const month = getMonth(input) + 1; // date-fns months are 0-indexed
  const date = getDate(input);
  const year = getYear(input);
  const dateObject = {
    month: month,
    day: date,
    year: year,
  };
  return dateObject;
};


// the following 3 can probably be combined into a general util func
export const getDefaultDateStatus = (input: BirthDate | null): FieldStatus  => {
  if (input === null) {
    return 'DEFAULT';
  }
  return 'COMPLETE';
}

export const getDefaultStringStatus = (input: string, conditions:StringConditions): FieldStatus  => {
  if (input === '') {
    return 'DEFAULT';
  } else if (isValidStringInput(input, conditions)) {
    return 'COMPLETE';
  } else {
    return 'ERROR';
  }
};

export const getDefaultMoneyStatus = (input: number | null, conditions:MoneyConditions): FieldStatus => {
  if (input === null) {
    return 'DEFAULT';
  } else if (isValidMoneyInput(input, conditions)) {
    return 'COMPLETE';
  } else {
    return 'ERROR';
  }
}


// same with these 3
// todo: move actual messages into consts/translations file
export const getMoneyHelperText = (
  status: FieldStatus,
  display: string,
  conditions: MoneyConditions,
): string => {
   if (status === 'COMPLETE') {
     return 'Complete';
   } else if (status === 'ERROR') {
     return `${display} must be between ${CURRENCY_LABEL}${formatter.format(conditions.minValue)} and ${CURRENCY_LABEL}${formatter.format(conditions.maxValue)}`;
   }
   return ' ';
};

export const getStringHelperText = (
  status: FieldStatus,
  display: string,
): string => {
  if (status === 'COMPLETE') {
    return 'Complete';
  } else if (status === 'ERROR') {
    return `Invalid ${display}`;
  }
  return ' ';
};

export const getDateHelperText = (status: FieldStatus): string => {
  if (status === 'COMPLETE') {
    return 'Complete';
  } else if (status === 'ERROR') {
    return 'Please enter a valid date';
  }
  return ' ';
};

export const getColorFromStatus = (status: FieldStatus): string => {
  if (status === 'COMPLETE') {
    return 'green';
  } else if (status === 'ERROR') {
    return 'red';
  }
  return 'black';
}

// these should probably be icons but will be
// emojis for time's sake
export const getAdornmentFromStatus = (status: FieldStatus): string => {
  if (status === 'COMPLETE') {
    return '✅ '
  } else if (status === 'ERROR') {
    return '🛑 '
  }
  return ' '
}