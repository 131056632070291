import styled from 'styled-components'
import { ConfigField, StringField, MoneyField, DateField } from '../configTypes';
import { FormData } from '../inputTypes';
import FormStringField from './FormStringField';
import FormDateField from './FormDateField';
import FormMoneyField from './FormMoneyField';

interface FormFieldProps {
  formField: ConfigField;
  localState: FormData;
  setLocalState: Function;
  storeState: FormData;
  setStoreState: Function;
};

function FormField({
  formField,
  localState,
  setLocalState,
  storeState,
  setStoreState,
}: FormFieldProps) {
  const { 
    type,
  } = formField;

  return (
    <StyledFormField>
      {
        { 
          // 'switch' syntax from https://stackoverflow.com/a/51432223
          'string': 
            <FormStringField 
              formField={formField as StringField} 
              localState={localState}
              setLocalState={setLocalState}
              storeState={storeState}
              setStoreState={setStoreState}
            />,
          'date': 
            <FormDateField
              formField={formField as DateField}
              localState={localState}
              setLocalState={setLocalState}
              storeState={storeState}
              setStoreState={setStoreState}
            />,
          'money': 
            <FormMoneyField 
              formField={formField as MoneyField} 
              localState={localState}
              setLocalState={setLocalState}
              storeState={storeState}
              setStoreState={setStoreState}
            />,
        }[type]
      }
    </StyledFormField>
  );
}

export default FormField;

const StyledFormField = styled.div`
  width: 100%;
`;