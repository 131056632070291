import styled from 'styled-components'
import { useState } from 'react';
import TextField from '@mui/material/TextField';
import { DateField, Entity } from '../configTypes';
import { FormData, BirthDate, FieldStatus } from '../inputTypes';
import FormHelperText from './FormHelperText';
import { DATE_FORMAT } from '../consts';
import { 
  handleChange, 
  updateStoreState, 
  convertDateObjectToDate, 
  convertDateToDateObject,
  getDefaultDateStatus,
  getDateHelperText,
 } from '../formUtils';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { parse, isValid } from 'date-fns';
import get from 'lodash/get';

interface FormDateFieldProps {
  formField: DateField;
  localState: FormData;
  setLocalState: Function;
  storeState: FormData;
  setStoreState: Function;
};

function FormDateField({
  formField,
  localState,
  setLocalState,
  storeState,
  setStoreState,
}: FormDateFieldProps) {
  const { 
    field,
    entity,
    display,
  } = formField;

  const storeValue = get(storeState, [entity, field], null);
  const [value, setValue] = useState<Date | null>(storeValue ? convertDateObjectToDate(storeValue) : null);
  const [fieldStatus, setFieldStatus] = useState<FieldStatus>(getDefaultDateStatus(storeValue));

  // DesktopDatePicker's onChange fires when the picker is used but ALSO when // the rendered input TextField
  // is changed too, so we have a conditional to identify when it was
  // actually the date picker being clicked, NOT the text field being changed.
  // this looks like pickerInput being a Date obj while textInput is undefined
  const handlePickerChange = (
    pickerInput: Date | null, 
    textInput: string | undefined 
  ): void => {
    if (isValid(pickerInput) && textInput === undefined) {
      setValue(pickerInput);
      setFieldStatus('COMPLETE');
      const dateObject = convertDateToDateObject(pickerInput);
      handleChange({
        value: dateObject,
        entity,
        localState,
        field,
        setLocalState
      });
     updateStoreState({
       entity,
       field,
       value: dateObject as BirthDate, // it wont be null
       storeState,
       setStoreState
     })
    };
    // currently typing something
    if (textInput !== undefined || fieldStatus !== 'ERROR') {
      setFieldStatus('DEFAULT');
    };
  };

  const handleBlur = (e: string): void => {
    // if valid, convert to date obj and save
    const date: Date = parse(e, DATE_FORMAT, new Date());
    // if the user blurs without actually entering anything
    if (e === '') {
      setFieldStatus('DEFAULT');
    }
    // if the user TYPES a valid date 
    else if (isValid(date)) {
      setFieldStatus('COMPLETE');
      const dateObject = convertDateToDateObject(date);
      updateStoreState({
        entity,
        field,
        value: dateObject as BirthDate,
        storeState,
        setStoreState
      })
    } else {
      setFieldStatus('ERROR');
    }
  };

  return (
    <StyledFormDateFieldContainer>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
          label={display}
          inputFormat={DATE_FORMAT}
          onChange={(pickerInput, textInput) => handlePickerChange(pickerInput, textInput)}
          value={value}
          InputProps={{
            "aria-label": `Date Input Field ${display}`
          }}
          renderInput={(params) => 
            <TextField 
              margin="normal" 
              fullWidth 
              size='small'
              onBlur={(e) => handleBlur(e.target.value)}
              {...params} 
              error={fieldStatus === 'ERROR'} // error should come after {...params}, see https://stackoverflow.com/a/71952902
            />
          }
        />
      </LocalizationProvider>
      <FormHelperText
        text={getDateHelperText(fieldStatus)}
        status={fieldStatus}
      />
    </StyledFormDateFieldContainer>
  );
}

export default FormDateField;

const StyledFormDateFieldContainer = styled.div`
  width: 100%;
`;