import { LoanData, BorrowerData } from './inputTypes';

export const EMPTY_LOAN_OBJECT: LoanData = {
  loanAmount: null,
  loanType: '', // this is Purchase in the sample but im assuming it would be a string field and therefore emptyString if empty
  downPaymentAmount: null,
};

export const EMPTY_BORROWER_OBJECT: BorrowerData = {
  firstName: '',
  lastName: '',
  birthDate: null,
};

export const DATE_FORMAT = 'MM/dd/yyyy';

export const CURRENCY_LABEL = '$';
