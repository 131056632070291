import styled from 'styled-components'
import { ConfigField } from '../configTypes';
import  { FormData } from '../inputTypes';
import FormField from './FormField';

interface FormSectionProps {
  sectionTitle: string;
  formFields: ConfigField[];
  localState: FormData;
  setLocalState: Function;
  storeState: FormData;
  setStoreState: Function;
};

function FormSection({
  sectionTitle,
  formFields,
  localState,
  setLocalState,
  storeState,
  setStoreState,
}: FormSectionProps) {
  return (
    <StyledFormSection>
      <StyledFormSectionHeader>
        {sectionTitle}
      </StyledFormSectionHeader>
      <div>
        {
          formFields.length > 0 ? formFields.map((f,i) => 
            <FormField 
              key={`field-${i}`}
              formField={f} 
              localState={localState}
              setLocalState={setLocalState}
              storeState={storeState}
              setStoreState={setStoreState}
            />
          ) : (
            <StyledEmptyFieldsMessage>No fields found</StyledEmptyFieldsMessage>
          )
        }
      </div>
    </StyledFormSection>
  );
}

export default FormSection;

const StyledFormSection = styled.div`
  max-width: 48rem;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 2rem;
`;

const StyledFormSectionHeader = styled.h2`
  margin: 0;
  text-align: left;
  padding-bottom: 0.5rem;
  font-size: 1.2rem;
`;

const StyledEmptyFieldsMessage = styled.div`
  text-align: left;
  font-size: 1rem;
`;