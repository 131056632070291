import { useState } from 'react';
import styled from 'styled-components'
import TextField from '@mui/material/TextField';
import FormHelperText from './FormHelperText';
import InputAdornment from '@mui/material/InputAdornment';
import { MoneyField } from '../configTypes';
import { FormData, FieldStatus } from '../inputTypes';
import { 
  handleChange, 
  addCommas, 
  removeNonNumeric, 
  unformatMoney, 
  updateStoreState, 
  isValidMoneyInput,
  getDefaultMoneyStatus, 
  getMoneyHelperText,
} from '../formUtils';
import { CURRENCY_LABEL } from '../consts';
import get from 'lodash/get';


interface FormMoneyFieldProps {
  formField: MoneyField;
  localState: FormData;
  setLocalState: Function;
  storeState: FormData;
  setStoreState: Function;
};

function FormMoneyField({
  formField,
  localState,
  setLocalState,
  storeState,
  setStoreState,
}: FormMoneyFieldProps) {

  const { 
    field,
    entity,
    display,
    conditions,
  } = formField;

  const value = get(localState, [entity, field], null);

  const [fieldStatus, setFieldStatus] = useState<FieldStatus>(getDefaultMoneyStatus(value, conditions));  

  const formattedValue = (addCommas(removeNonNumeric(value)));

  // maybe generalize this into a util function
  const handleBlur = (input: number): void => {
    if (Number.isNaN(input)) {
      setFieldStatus('DEFAULT');
    } else if (isValidMoneyInput(input, conditions)) {
      setFieldStatus('COMPLETE');
      updateStoreState({
        entity,
        field,
        value: input,
        storeState,
        setStoreState,
      })
    } else {
      setFieldStatus('ERROR');
    }
  };

  return (
    <>
      <TextField
        id="outlined-basic"
        label={display}
        onChange={(e) => {
          if (fieldStatus === 'COMPLETE') {
            setFieldStatus('DEFAULT');
          }
          handleChange({
            value: unformatMoney(e.target.value),
            entity,
            localState,
            field,
            setLocalState,
          })
        }}
        onBlur={(e) => handleBlur(unformatMoney(e.target.value))}
        value={formattedValue}
        error={fieldStatus === 'ERROR'}
        margin='normal'
        fullWidth
        size='small'
        InputProps={{
          startAdornment: <InputAdornment position="start">{CURRENCY_LABEL}</InputAdornment>,
          "aria-label": `Money Input Field ${display}`
        }}
      />
      <FormHelperText
        text={getMoneyHelperText(fieldStatus, display, conditions)}
        status={fieldStatus}
      />
    </>
  );
}

export default FormMoneyField;
