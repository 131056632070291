import { FieldStatus } from '../inputTypes';
import FormHelperText from '@mui/material/FormHelperText';
import { getColorFromStatus, getAdornmentFromStatus } from '../formUtils';

interface FormHelperTextProps {
  text: string;
  status: FieldStatus;
};

function FormField({
  text,
  status,
}: FormHelperTextProps) {
  const color: string = getColorFromStatus(status);
  const adornment: string = getAdornmentFromStatus(status);
  return (
    <FormHelperText 
      sx={{
        color: {color},
        fontWeight: 'bold',
        padding: '0 0 0.3rem 0',
      }}
    >
      {/* empty char at end so it maintains size even if blank */ }
      {adornment}{text}{"\u00a0"} 
    </FormHelperText>
  );
}

export default FormField;
