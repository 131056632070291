import { useState } from 'react';
import './App.css';
import { ConfigField } from './configTypes';
import _mockConfig from './sample1.json';
import styled from 'styled-components'
import FormSection from './Components/FormSection';

function App() {
  const mockConfig= _mockConfig as ConfigField[]; 

  // backStoreData is validated data
  const storageData = JSON.parse(localStorage.getItem('backStoreData') || '{}'); 

  // localData is whatever is currently in the fields regardless of validity
  const localData = JSON.parse(localStorage.getItem('localData') || '{}');

  const [localState, setLocalState] = useState(localData); 
  const [storeState, setStoreState] = useState(storageData);

  // separate Borrower vs. Loan fields
  const borrowerFields: ConfigField[] = mockConfig.filter((e: ConfigField) => e.entity === 'Borrower');
  const loanFields: ConfigField[] = mockConfig.filter((e: ConfigField) => e.entity === 'Loan');

  return (
    <StyledAppContainer className="App">
      <StyledFormContainer>
          <FormSection
            sectionTitle={'Borrower'}
            formFields={borrowerFields}
            localState={localState}
            setLocalState={setLocalState}
            storeState={storeState}
            setStoreState={setStoreState}
          />
          <FormSection
            sectionTitle={'Loan'}
            formFields={loanFields}
            localState={localState}
            setLocalState={setLocalState}
            storeState={storeState}
            setStoreState={setStoreState}
          />
      </StyledFormContainer>
    </StyledAppContainer>
  );
}

export default App;

const StyledAppContainer = styled.div`
  font-size: 62.5%;
  background-color: white;
  height: 90%;
  width: 100vw;
  padding: 10% 0 0 0 ;
  margin: 0;
  display: flex;
  justify-content: center;
}
`;

const StyledFormContainer = styled.div`
  width: 24rem;
`;